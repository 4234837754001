import {takeEvery, put, call} from 'redux-saga/effects';
import {appHistory} from '@services/HistoryConfig';
import {PAYMENTS_ACTIONS} from '@constants';
import {paymentsActions, profileActions, globalActions} from '@actions';
import {processRequest} from '@services/Api';
import { ROUTES } from '@router/routes';
import { push } from 'connected-react-router'

function* handleBuyTicket(action) {
  try {
    const {payload} = action;
    const {payment} = payload;
    const requestPayload = {payment};
    const {data} = yield call(
      processRequest,
      '/stripe_payments',
      'POST',
      requestPayload,
    );
    if (data.message) {
      yield put(paymentsActions.buyTicketSuccess());
      appHistory.back();
      yield put(globalActions.setSuccess(data.message || ''));
    }
  } catch (e) {
    const {response} = e;
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (typeof error_messages === 'string') {
        message = error_messages;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(paymentsActions.buyTicketError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(paymentsActions.buyTicketError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(
        paymentsActions.buyTicketError(statusText || 'Internal server error.'),
      );
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(paymentsActions.buyTicketError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(paymentsActions.buyTicketError('Internal server error.'));
    }
  }
}

function* handleDonate (action) {
  const { payload } = action;
  const { billingDetails, cardElement, stripe } = payload.payment;

  // get clientSecret
  try {
    yield put(paymentsActions.toggleIsProccessing(true))
    const { data: { client_secret } } = yield call(processRequest, '/payments', 'POST', billingDetails)
    console.log(client_secret)

    // create payment method
    const {error, paymentMethod} = yield call(stripe.createPaymentMethod, {
      type: "card",
      card: cardElement
    })
    // confirm payment
    const { paymentIntent } = yield call(stripe.confirmCardPayment, client_secret, {
      payment_method: paymentMethod.id
    })

    console.log(paymentIntent)

    if(paymentIntent.status === 'succeeded') {
      // console.log('ksksaksak')
      // yield put(paymentsActions.donateSuccess(paymentIntent))
      yield put(paymentsActions.toggleDonateModal())
      yield put(paymentsActions.donateSuccess())
      // yield put(globalActions.toggleSuccessModal({ show: true, message: 'Donate was successfully sent' }))
    }

    yield put(paymentsActions.toggleIsProccessing(false))
    // console.log(confirmedCardPayment)
    
  } catch (e) {
    console.log(e.message)
    yield put(paymentsActions.toggleIsProccessing(false))
  }
  
}

function* handleSubscribe (action) {
  const { payload } = action;
  const { cardElement, stripe } = payload.payment;

  // get clientSecret
  try {
    yield put(paymentsActions.toggleIsProccessing(true))

    // create payment method
    const {error, paymentMethod} = yield call(stripe.createPaymentMethod, {
      type: "card",
      card: cardElement
    })

    const { data: { payment_intent_client_secret: client_secret } } = yield call(processRequest, '/stripe/charges', 'POST', {id: paymentMethod.id});

    // confirm payment
    const { paymentIntent } = yield call(stripe.confirmCardPayment, client_secret, {
      payment_method: paymentMethod.id
    })


    if(paymentIntent.status === 'succeeded') {
      // console.log('ksksaksak')
      // yield put(paymentsActions.donateSuccess(paymentIntent))
      // yield put(paymentsActions.toggleDonateModal())
      const delay = time => new Promise(resolve => setTimeout(resolve, time));

      yield put(paymentsActions.subscribeSuccess());

      yield put(paymentsActions.toggleIsProccessing(false));

      yield put(globalActions.toggleSuccessModal({ show: true, message: 'Your payment was successful' }))

      yield call(delay, 2000);
      
      yield put(profileActions.getCurrentUser());
    }


    // console.log(confirmedCardPayment)
    
  } catch (e) {
    console.log(e.message)
    yield put(paymentsActions.toggleIsProccessing(false))
    yield put(paymentsActions.subscribeError(e))
    yield put(globalActions.toggleSuccessModal({ show: true, message: 'Your payment failed. Please try again' }))
  }
}

function* handleCancelSubscription(action) {
  try {
    const { data } = yield call(processRequest, '/stripe/charges/cancel_subscription');

    if(data) {
      const delay = time => new Promise(resolve => setTimeout(resolve, time));
      yield put(paymentsActions.cancelSubscriptionSuccess())
      yield call(delay, 2000);
      
      yield put(profileActions.getCurrentUser());
      yield put(push(ROUTES.WEBINARS));
    }
  } catch (e) {
    
    console.log(e)
    yield put(paymentsActions.cancelSubscriptionError(e))
  }
}

function* handleGetTransactions(action) {
  try {
    const { data } = yield call(processRequest, '/transactions')

    if(data) {
      console.log(data)
      yield put(paymentsActions.getTransactionsSuccess(data?.transactions?.data?.map(el => el.attributes)))
    }
  } catch (e) {
    console.log(e)
    yield put(paymentsActions.getTransactionsError(e))
  }
}

function* handleUpdateCard (action) {
  const { payload } = action;
  const { cardElement, stripe } = payload.payment;
  yield put(paymentsActions.toggleIsProccessing(true))

  // get clientSecret
  try {
    // yield put(paymentsActions.toggleIsProccessing(true))
    // const { data: { payment_intent_client_secret: client_secret } } = yield call(processRequest, '/stripe/charges', 'POST', {})
    // console.log(client_secret)

    // create payment method
    const {error, paymentMethod} = yield call(stripe.createPaymentMethod, {
      type: "card",
      card: cardElement
    })

    // console.log(paymentMethod.id)
    
    yield put(profileActions.updateProfile({ payment_method_id: paymentMethod.id }))
    yield put(globalActions.toggleSuccessModal({ show: true, message: 'Your card was successfuly updated' }))
    yield put(paymentsActions.toggleIsProccessing(false))
    // console.log(confirmedCardPayment)

  } catch (e) {
    console.log(e.message)
    yield put(paymentsActions.toggleIsProccessing(false))
    yield put(paymentsActions.updateCardError(e))
    yield put(globalActions.toggleSuccessModal({ show: true, message: 'Something went wrong with updating your card' }))
  }
}


export function* watchPaymentsSagas() {
  yield takeEvery(PAYMENTS_ACTIONS.BUY_TICKET, handleBuyTicket);
  yield takeEvery(PAYMENTS_ACTIONS.DONATE, handleDonate);
  yield takeEvery(PAYMENTS_ACTIONS.SUBSCRIBE, handleSubscribe);
  yield takeEvery(PAYMENTS_ACTIONS.CANCEL_SUBSCRIPTION, handleCancelSubscription);
  yield takeEvery(PAYMENTS_ACTIONS.GET_TRANSACTIONS, handleGetTransactions);
  yield takeEvery(PAYMENTS_ACTIONS.UPDATE_CARD, handleUpdateCard);
}
